<template>
  <dashboard-layout v-slot="userAccess">
    <div class="w-full flex flex-col">
      <div
        v-if="userAccess.userAccess.access.isRevoke"
        class="text-red-400 font-bold w-full text-center mb-5"
      >
        <p>
          Seu acesso foi expirado em
          {{ userAccess.userAccess.access.revoke_access_at }}.
        </p>
        <p>
          Entre em contato com o seu sindicato para liberação do seu acesso
          definitivo.
        </p>
        <p>
          Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
          Telefone:
          {{
            userAccess.userAccess.informations.syndicate.telephone
              | VMask('(##) ####-####')
          }}
        </p>
      </div>
      <h1 class="text-green-400 font-bold text-4xl w-full text-center">
        Iniciar <span class="text-green-500">Colheita</span>
      </h1>
      <form @submit.prevent="submit" class="w-10/12 lg:w-6/12 mx-auto">
        <div class="flex flex-col">
          <div
            class="flex mt-3 rounded-full items-center overflow-hidden shadow-md bg-white"
          >
            <input
              class="text-gray-500 placeholder-gray-500 place w-full py-3 px-5 outline-none font-normal"
              type="text"
              name="search"
              autocomplete="off"
              placeholder="Busque por: Tratorista, Vaqueiro, Operador de Máquinas..."
              v-model="search"
              @input="searchKeyword"
            />
            <button
              type="submit"
              class="text-gray-500 cursor-pointer py-3 px-3 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            >
              <i class="ri-search-line text-2xl"></i>
            </button>
          </div>
          <div
            v-if="keywords.length > 0"
            class="flex flex-col w-full bg-white rounded-lg mt-4 py-2 px-4 max-h-64 overflow-scroll"
          >
            <div
              class="cursor-pointer my-1 hover:bg-gray-100"
              v-for="keyword in keywords"
              :key="keyword"
              @click="selectKeyword(keyword)"
            >
              {{ keyword }}
            </div>
          </div>
        </div>
        <div class="w-6/12 mx-auto text-center mt-3">
          <button
            type="button"
            class="font-bold text-green-500 focus:outline-none"
            @click="showAdvancedSearch = !showAdvancedSearch"
          >
            Busca Avançada
          </button>
        </div>
        <div
          v-if="showAdvancedSearch"
          class="w-full bg-white rounded-lg py-3 px-5 mx-auto mt-3"
        >
          <div class="flex flex-col w-full mb-5">
            <label class="flex items-center mb-2" for="Experience">
              Experiência Profissional
              <field-tooltip
                text="Procure talentos pela suas experências profissionais."
              ></field-tooltip>
            </label>
            <multiselect
              v-model="advancedSearch.experiences.selected"
              id="Experience"
              label="name"
              track-by="name"
              :max="1"
              placeholder="Pesquise experências profissionais aqui..."
              open-direction="bottom"
              :options="advancedSearch.experiences.options"
              :searchable="true"
              selected-label="Selecionado"
              deselect-label="Remover"
              :taggable="true"
              :multiple="true"
              select-label="Selecionar"
              :loading="advancedSearch.experiences.isLoading"
              :internal-search="false"
              :options-limit="100"
              :max-height="600"
              :show-no-results="false"
              @tag="addTag"
              @search-change="fetchExperience"
            >
              <template v-slot:maxElements>
                Máximo de 1 opções selecionadas. Primeiro remova uma opção
                selecionada para selecionar outra.</template
              >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
              <span slot="noResult"
                >Oops! Nenhuma sugestão de experiência profissional
                encontrado.</span
              >
              <span slot="noOptions"
                >Comece a pesquisar para que as experiência profissionais
                apareçam aqui.</span
              >
            </multiselect>
          </div>

          <div class="flex flex-col w-full mb-5">
            <label class="flex items-center mb-2" for="Experience">
              Marca de máquinas
              <field-tooltip
                text="Procure talentos capacitados em marcas de maquinas especificas."
              ></field-tooltip>
            </label>
            <multiselect
              v-model="advancedSearch.brand.selected"
              id="brand"
              label="brand"
              placeholder="Pesquise talentos capacitados em marcas especificas aqui..."
              open-direction="bottom"
              :options="advancedSearch.brand.options"
              :searchable="true"
              selected-label="Selecionado"
              deselect-label="Remover"
              select-label="Selecionar"
              :internal-search="false"
              :show-no-results="false"
            >
              <span slot="noResult"
                >Oops! Nenhuma sugestão de experiência profissional
                encontrado.</span
              >
              <span slot="noOptions"
                >Comece a pesquisar para que as experiência profissionais
                apareçam aqui.</span
              >
            </multiselect>
          </div>

          <div class="sm:flex mb-5 w-full">
            <div
              class="flex flex-row items-baseline w-full mb-5 sm:mb-0 sm:mr-5"
            >
              <input
                v-model="advancedSearch.complementaryCurriculum"
                type="checkbox"
                name="complementary_curriculum"
                id="complementary_curriculum"
                class="border border-black rounded-md mr-2 py-2 px-2"
              />
              <label
                class="flex items-center mb-2"
                for="complementary_curriculum"
              >
                Talento com currículo complementar
                <field-tooltip
                  text="Selecione caso queira talentos que tenham completado o seu currículo"
                ></field-tooltip>
              </label>
            </div>
          </div>

          <div class="sm:flex mb-5 w-full">
            <div class="flex flex-col sm:w-4/12 mb-5 sm:mb-0 sm:mr-5">
              <label class="flex items-center mb-2" for="education-level">
                Escolaridade Mínima
                <field-tooltip
                  text="Para uma busca mais refinida selecione uma opção de escolaridade."
                ></field-tooltip>
              </label>
              <multiselect
                id="education-level"
                v-model="advancedSearch.educationLevels.selected"
                deselect-label="Remover"
                track-by="name"
                label="name"
                select-label="Selecionar"
                selected-label="Selecionado"
                placeholder="Selecione uma escolaridade"
                :options="advancedSearch.educationLevels.options"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
                <span slot="noOptions">Nenhuma escolaridade encontrada.</span>
              </multiselect>
            </div>

            <div class="flex flex-col sm:w-4/12 mb-5 sm:mb-0 sm:mr-5">
              <label class="mb-2" for="gender">Sexo</label>
              <multiselect
                id="gender"
                v-model="advancedSearch.genders.selected"
                deselect-label="Remover"
                track-by="label"
                label="label"
                select-label="Selecionar"
                selected-label="Selecionado"
                placeholder="Selecione"
                :options="advancedSearch.genders.options"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
              </multiselect>
            </div>

            <div class="flex sm:w-4/12 mb-5 sm:mb-0">
              <div class="flex w-6/12 flex-col mr-3">
                <label class="mb-2" for="min-age">Idade Mínima</label>
                <input
                  v-model="advancedSearch.minAge"
                  type="number"
                  id="min-age"
                  name="min_age"
                  class="w-full border border-black rounded-md py-2 px-2"
                />
              </div>

              <div class="flex w-6/12 flex-col">
                <label class="mb-2" for="max-age">Idade Máxima</label>
                <input
                  v-model="advancedSearch.maxAge"
                  type="number"
                  id="max-age"
                  name="max_age"
                  class="w-full border border-black rounded-md py-2 px-2"
                />
              </div>
            </div>
          </div>

          <div class="sm:flex mb-5 w-full">
            <div class="flex w-full mr-3 flex-col">
              <label class="flex items-center mb-2" for="cities">
                Municípios
                <field-tooltip
                  text="Informe o nome dos municípios dos quais deseja buscar talentos."
                ></field-tooltip>
              </label>
              <multiselect
                id="cities"
                v-model="advancedSearch.cities.selected"
                tag-placeholder="Adicionar cidade"
                placeholder="Procure ou adicione uma cidade"
                label="name"
                track-by="name"
                :options="advancedSearch.cities.filteredOptions"
                :multiple="true"
                :taggable="true"
                select-label="Selecionar"
                deselect-label="Remover"
                selected-label="Selecionado"
                @tag="addCity"
                :internal-search="false"
                @search-change="citiesSearchChange"
              >
                <span slot="noOptions"
                  >Comece a pesquisar para que as cidades apareçam aqui.</span
                >
              </multiselect>
            </div>
            <!-- <div class="flex w-8/12 flex-col">
              <label class="flex items-center mb-2" for="cities">
                Ano de Fim (mínimo)
                <field-tooltip
                  text="Informe o ano mínimo de fim do treinamento."
                ></field-tooltip>
              </label>
              <multiselect
                id="year"
                v-model="advancedSearch.years.selected"
                deselect-label="Remover"
                track-by="label"
                label="label"
                select-label="Selecionar"
                selected-label="Selecionado"
                placeholder="Selecione"
                :options="advancedSearch.years.options"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>
              </multiselect>
            </div> -->
          </div>

          <div class="flex mb-5 w-full flex-col">
            <label class="flex items-center mb-2" for="name">
              Nome do Talento
              <field-tooltip
                text="Caso queira realizar uma busca por um talento específico, digite aqui o nome dele e nós traremos para você."
              ></field-tooltip>
            </label>
            <input
              v-model="advancedSearch.name"
              class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="name"
              type="text"
              placeholder="Nome do Talento"
            />
          </div>

          <button
            type="submit"
            class="focus:outline-none rounded-md py-2 bg-green-600 text-white text-center w-full text-xl cursor-pointer"
          >
            Aplicar filtros
          </button>
        </div>
      </form>
      <br />
      <br />
      <br />
      <div>
        <div class="divcarousel" v-if="loadbanner">
          <ssr-carousel
            class="carousel relative"
            :slides-per-page="1"
            show-dots
            show-arrows
            :autoplay-delay="5"
            loop
          >
            <div v-for="banner in banners" :key="banner.id">
              <a class="imagebanner" :href="banner.url"
                ><img :src="banner.image"
              /></a>
            </div>
          </ssr-carousel>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../layouts/DashboardLayout';
import FieldTooltip from '../../components/FieldTooltip';
import store from '@/store';
import axios from '@/utils/axios';
import moment from 'moment';

export default {
  name: 'Search',

  title() {
    return `${process.env.VUE_APP_NAME} | Iniciar Colheita`;
  },

  components: {
    DashboardLayout,
    FieldTooltip
  },

  data() {
    return {
      advancedSearch: {
        experiences: {
          isLoading: false,
          options: [],
          draw: 0,
          selected: []
        },
        brand: {
          options: [],
          selected: null
        },
        educationLevels: {
          options: [],
          selected: null
        },
        genders: {
          options: [
            { label: 'Masculino', value: 'M' },
            { label: 'Feminino', value: 'F' }
          ],
          selected: null
        },
        years: {
          options: [],
          selected: null
        },
        minAge: 18,
        maxAge: 90,
        cities: {
          options: [],
          filteredOptions: [],
          selected: []
        },
        name: '',
        complementaryCurriculum: false
      },
      loadbanner: false,
      banners: [],
      isLoading: false,
      search: '',
      showAdvancedSearch: false,
      keywords: []
    };
  },

  computed: {
    /**
     * Conta quantos campos da pesquisa avançada foram preenchidos
     *
     * Regra:
     *  - Se o campo for objeto, verifica o valor do atributo selected, caso
     *  ele não seja array, verifico somente se é diferente de nulo, caso
     *  contrário valido se possui algum elemento dentro do array
     *  - Se o campo não for objeto, apenas verifico se ele não está vazio
     *
     * @returns {number}
     */
    countAdvancedSearchFieldsFilled() {
      return Object.values(this.advancedSearch).filter(
        field =>
          (typeof field === 'object' &&
            ((!Array.isArray(field.selected) && field.selected !== null) ||
              (Array.isArray(field.selected) && field.selected.length))) ||
          (typeof field !== 'object' && field)
      ).length;
    }
  },

  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        label: newTag
      };
      this.advancedSearch.experiences.selected.push(tag);
    },

    async fetchbanners() {
      await axios.get('api/banners/viewbanner/slider').then(response => {
        response.data.data.forEach(ban => {
          if (ban.image) {
            this.banners.push({
              image: ban.image,
              id: ban.id,
              url: ban.url_link
            });
          }
        });
      });
      this.loadbanner = true;
    },

    fetchExperience(search) {
      this.advancedSearch.experiences.isLoading = true;
      this.advancedSearch.experiences.draw++;

      axios
        .post('api/experiencias/buscar/exp', {
          name: search,
          draw: this.advancedSearch.experiences.draw
        })
        .then(({ data }) => {
          if (this.advancedSearch.experiences.draw !== data.draw) return;

          this.advancedSearch.experiences.options = [];

          data.data.forEach(exp => {
            this.advancedSearch.experiences.options.push({
              name: exp.title,
              id: exp.id
            });
          });

          this.advancedSearch.experiences.draw = 0;
        })
        .finally(() => {
          this.advancedSearch.experiences.isLoading = false;
        });
    },

    addCity(city) {
      const tag = {
        name: city,
        id: city
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    selectKeyword(keyword) {
      this.search = keyword;

      this.keywords = [];
    },

    searchBrand() {
      axios.get('api/palavras-chave/course-brand').then(({ data }) => {
        console.log(data.data);
        this.advancedSearch.brand.options = data.data;
      });
    },

    searchKeyword() {
      if (this.search.length >= 3) {
        return axios
          .post('api/busca/palavras-chave', {
            search: this.search
          })
          .then(response => {
            this.keywords = [];

            response.data.data.forEach(keyword =>
              this.keywords.push(keyword.label)
            );
          });
      }
    },

    async fetchEducationLevels() {
      const response = await axios.get('api/escolaridades');

      response.data.data.forEach(educationLevel => {
        this.advancedSearch.educationLevels.options.push({
          name: educationLevel.name,
          order: educationLevel.order
        });
      });
    },

    async fetchCities() {
      await axios.get('api/estados/11/cidades').then(response => {
        response.data.data.forEach(city => {
          this.advancedSearch.cities.options.push({
            name: city.name,
            id: city.id
          });
        });
      });
    },

    fetchYears() {
      let year = moment().year();

      do {
        this.advancedSearch.years.options.push({
          label: year,
          value: year
        });

        year--;
      } while (year >= 2014);
    },

    submit: function() {
      if (this.search.length < 3 && this.countAdvancedSearchFieldsFilled < 3) {
        this.$toast.warning('A busca deve ter mais de 3 caracteres.');

        return false;
      }

      store.commit('setSearch', this.search);

      if (this.advancedSearch.experiences.selected[0] != null) {
        store.commit(
          'setExperience',
          this.advancedSearch.experiences.selected[0].name
        );
      }

      if (this.advancedSearch.brand.selected != null) {
        store.commit('setBrand', this.advancedSearch.brand.selected['brand']);
      }

      if (this.advancedSearch.educationLevels.selected != null) {
        store.commit(
          'setEducationLevel',
          this.advancedSearch.educationLevels.selected.order
        );
      }

      if (this.advancedSearch.genders.selected != null) {
        store.commit('setGender', this.advancedSearch.genders.selected.value);
      }

      if (this.advancedSearch.minAge !== '') {
        store.commit('setMinAge', this.advancedSearch.minAge);
      }

      if (this.advancedSearch.maxAge !== '') {
        store.commit('setMaxAge', this.advancedSearch.maxAge);
      }

      let cities = this.advancedSearch.cities.selected;

      store.commit('setCities', cities);

      if (this.advancedSearch.name !== '') {
        store.commit('setParticipantName', this.advancedSearch.name);
      }

      if (this.advancedSearch.years.selected != null) {
        store.commit('setYear', this.advancedSearch.years.selected.value);
      }

      store.commit(
        'setComplementaryCurriculum',
        this.advancedSearch.complementaryCurriculum
      );

      localStorage.setItem(
        'searchParams',
        JSON.stringify({
          'Palavra Buscada': this.search,
          Experience: this.advancedSearch.experiences.selected.length
            ? this.advancedSearch.experiences.selected[0].name
            : null,
          Escolaridade: this.advancedSearch.educationLevels.selected
            ? this.advancedSearch.educationLevels.selected.label
            : null,
          Sexo: this.advancedSearch.genders.selected
            ? this.advancedSearch.genders.selected.label
            : null,
          'Idade Mínima': this.advancedSearch.minAge,
          'Idade Máxima': this.advancedSearch.maxAge,
          'Cidades Selecionadas': cities ?? null,
          'Nome do Talento': this.advancedSearch.name,
          'Ano de Fim': this.advancedSearch.years.selected
            ? this.advancedSearch.years.selected.label
            : null,
          'Curriculo Complementar': this.advancedSearch.complementaryCurriculum,
          brand: this.advancedSearch.brand.selected
            ? this.advancedSearch.brand.selected.label
            : null
        })
      );

      this.$router.push({ name: 'search-results' });
    },

    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },

    citiesSearchChange(search) {
      this.advancedSearch.cities.filteredOptions = search
        ? this.advancedSearch.cities.options.filter(option =>
            this.normalizedContains(search, option.name)
          )
        : this.advancedSearch.cities.options;
    }
  },
  async created() {
    await this.fetchbanners();
    this.fetchEducationLevels();
    this.fetchCities();
    this.fetchYears();
    this.searchBrand();
    //store.commit('clearFields');
  }
};
</script>

<style scoped>
@media screen and (min-width: 700px) {
  a.imagebanner {
    width: 800px;
    height: 145px;
  }
}

@media screen and (min-width: 1200px) {
  a.imagebanner {
    width: 1200px;
    height: 245px;
  }
}

.divcarousel {
  display: flex;
  justify-content: center;
}

.carousel {
  width: 70%;
}

a.imagebanner {
  display: flex;
  justify-content: center;
  /* width: 1200px;
    height: 245px; */
}
</style>
